import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formControlClasses, Link, Modal } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { logoutUser } from "../../actions/authActions";
import {
  listUsers,
  listSentimentDetails,
  editSentiment,
  sendSentimentNotification,
} from "../../actions/sourceActions";

import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

import axios from "axios";

class SentimentTab extends Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      sources: [],
      users: [],
      selectedUser: null,
      startDate: dayjs().subtract(1, "month"),
      endDate: dayjs(),
      comments: [],
      newComment: null,
      loggedInUser: "",
      canEditSentiment: false,
      feedback: "",
      slackMessageSent: false,
      slackMessageError: false,
      editSentimentSuccess: false,
      editSentimentError: false,
      status: "all",
      correctSentimentByReject: null,
      currentRow: null,
      rejectModalOpen: false,
    };
    this.init = this.init.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onDisplayClick = this.onDisplayClick.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.onFeedbackChange = this.onFeedbackChange.bind(this);
    this.onSendNotification = this.onSendNotification.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      this.setState({
        users: nextProps.auth.users,
      });
    }
    if (nextProps.auth.comments) {
      this.setState({
        comments: nextProps.auth.comments,
      });
    }
    if (nextProps.auth.newComment) {
      const data = this.state.comments;

      const index = data.findIndex(
        (comment) =>
          comment.commentId == nextProps.auth.newComment.commentId &&
          comment.created_at == nextProps.auth.newComment.created_at
      );

      data[index] = nextProps.auth.newComment;
      if (!data[index].feedback) {
        data[index].feedback = "";
      }
      this.setState({
        comments: data,
      });
    }
    if (nextProps.auth.email) {
      this.setState({
        loggedInUser: nextProps.auth.email,
      });
    }
    if (nextProps.auth.canEditSentiment) {
      this.setState({
        canEditSentiment: nextProps.auth.canEditSentiment,
      });
    }
    if (nextProps.auth.slackMessageSent) {
      this.setState({
        slackMessageSent: nextProps.auth.slackMessageSent,
      });
    }
    if (nextProps.auth.slackMessageError) {
      this.setState({
        slackMessageError: nextProps.auth.slackMessageError,
      });
    }
    if (nextProps.auth.editSentimentSuccess) {
      this.setState({
        editSentimentSuccess: nextProps.auth.editSentimentSuccess,
      });
    }
    if (nextProps.auth.editSentimentError) {
      this.setState({
        editSentimentError: nextProps.auth.editSentimentError,
      });
    }
  }

  init() {
    this.props.listUsers();
  }

  onSourceChange(e, value) {
    this.setState({
      selectedSources: value,
    });
  }

  onUserChange(e, value) {
    this.setState({
      selectedUser: value,
    });
  }

  onStartDateChange(value) {
    this.setState({
      startDate: value,
    });
  }

  onEndDateChange(value) {
    this.setState({
      endDate: value,
    });
  }

  onFeedbackChange(commentId, feedback) {
    const data = this.state.comments;

    const index = data.findIndex((comment) => comment.commentId == commentId);
    data[index].feedback = feedback.target.value;
    this.setState({
      comments: data,
    });
  }

  onDisplayClick(e) {
    e.preventDefault();
    this.state.selectedUser === "All Users"
      ? this.props.listSentimentDetails({
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          status: this.state.status,
          sentimentType: "sentiment", // sentimentType: "sentiment" or "spam"
        })
      : this.props.listSentimentDetails({
          username: this.state.selectedUser,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          status: this.state.status,
          sentimentType: "sentiment", // sentimentType: "sentiment" or "spam"
        });
  }

  onSendNotification(e) {
    e.preventDefault();
    this.props.sendSentimentNotification({
      username: this.state.selectedUser,
      updatedBy: this.state.loggedInUser,
    });
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onStatusChange(e) {
    e.preventDefault();
    console.log("SENTIMENT CHANGE", e);
    this.setState({
      status: e.target.value,
    });
  }

  onEditFeedback(data) {
    axios
      .put("/api/sentiment-tab/feedback", data)
      .then((res) => {
        console.log("SUCCESS", res);
      })
      .catch((err) => {
        console.log("FAILED", err);
      });
  }

  render() {
    const renderTable = (comments) => {
      const rows = comments;
      return (
        <>
          <Box sx={{ display: "flex", alignItems: "baseline", marginLeft: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: 20, marginRight: 1 }}>
              Status:
            </Typography>

            <Select
              label="Status"
              disabled={this.state.selectedUser == null}
              // onChange={(e) => {
              //   console.log('STATUS VALUE', e);
              //   this.setState({ status: e.target.value });
              //   }}
              onChange={this.onStatusChange}
              value={this.state.status}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"rejected"}>Rejected</MenuItem>
            </Select>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {this.state.selectedUser === "All Users" && (
                    <TableCell>Username</TableCell>
                  )}
                  <TableCell>Request Date</TableCell>
                  <TableCell>Comment ID</TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell>Comment</TableCell>
                  <TableCell>Model Sentiment</TableCell>
                  <TableCell>User Sentiment</TableCell>
                  <TableCell>Status</TableCell>
                  {this.state.canEditSentiment ? (
                    <TableCell>Approve</TableCell>
                  ) : null}
                  {this.state.canEditSentiment ? (
                    <TableCell>Reject</TableCell>
                  ) : null}
                  {this.state.canEditSentiment ? (
                    <TableCell>Undo</TableCell>
                  ) : null}
                  <TableCell>Feedback</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.commentId + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {this.state.selectedUser === "All Users" && (
                      <TableCell>{row.username}</TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {row.created_at.replace("T", " ").replace("Z", "")}
                    </TableCell>
                    <TableCell>
                      <div>{row.commentId}</div>
                    </TableCell>
                    <TableCell>
                      {row.data ? (
                        <Link
                          href={row.data.permalink}
                          target="_blank"
                          style={{
                            display: "block",
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {row.data.permalink}
                        </Link>
                      ) : (
                        <div>N/A</div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          // display: "-webkit-box",
                          // WebkitLineClamp: 4,
                          // webkitBoxOrient: "vertical",
                          // textOverflow: "ellipsis",
                          width: "420px",
                          // overflow: "hidden",
                        }}
                      >
                        {row.data ? row.data.message : "N/A"}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{row.oldSentiment}</div>
                    </TableCell>
                    <TableCell>
                      <div>{row.newSentiment}</div>
                    </TableCell>
                    <TableCell>
                      <div>{row.status}</div>
                    </TableCell>
                    {this.state.canEditSentiment && (
                      <TableCell>
                        <Button
                          disabled={row.status != "pending"}
                          onClick={() => {
                            this.props.editSentiment({
                              username: row.username,
                              commentId: row.commentId,
                              status: "approve",
                              platform: row.data.platform,
                              newSentiment: row.newSentiment,
                              updatedBy: this.state.loggedInUser,
                              feedback: row.feedback,
                              _id: row._id,
                            });
                            // this.setState({});
                          }}
                        >
                          Approve
                        </Button>
                      </TableCell>
                    )}
                    {this.state.canEditSentiment && (
                      <TableCell>
                        <Button
                          disabled={row.status != "pending"}
                          onClick={() => {
                            this.setState({
                              rejectModalOpen: true,
                              currentRow: row,
                            });
                          }}
                        >
                          Reject
                        </Button>
                      </TableCell>
                    )}
                    {this.state.canEditSentiment && (
                      <TableCell>
                        <Button
                          disabled={row.status == "pending"}
                          onClick={() => {
                            this.props.editSentiment({
                              username: row.username,
                              commentId: row.commentId,
                              oldStatus: row.status,
                              status: "undo",
                              platform: row.data.platform,
                              newSentiment: row.newSentiment,
                              oldSentiment: row.oldSentiment,
                              updatedBy: this.state.loggedInUser,
                              _id: row._id,
                            });
                          }}
                        >
                          Undo
                        </Button>
                      </TableCell>
                    )}
                    <TableCell>
                      {this.state.canEditSentiment ? (
                        <Box
                          component="form"
                          sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            value={row.feedback}
                            onChange={(e) =>
                              this.onFeedbackChange(row.commentId, e)
                            }
                            variant="outlined"
                          />

                          <Button
                            // disabled={!row.feedback.length}
                            onClick={() =>
                              this.onEditFeedback({
                                username: row.username,
                                commentId: row.commentId,
                                platform: row.data.platform,
                                newSentiment: row.newSentiment,
                                updatedBy: this.state.loggedInUser,
                                feedback: row.feedback,
                                _id: row._id,
                              })
                            }
                          >
                            Save Feedback
                          </Button>
                        </Box>
                      ) : (
                        <div>{row.feedback}</div>
                      )}
                      {/* <Button variant="contained">Submit</Button> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    };

    const { user } = this.props.auth;
    const options = ["All Users", ...this.state.users];
    return (
      <div className="landing-copy col s12 center-align">
        <h4>
          <b>Welcome</b> {user.name.split(" ")[0]}
        </h4>
        <div className="row">
          <div className="col s6">
            <Autocomplete
              id="tags-standard"
              options={options}
              filterSelectedOptions
              onChange={this.onUserChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Selected Users"
                  placeholder="User"
                />
              )}
            />
          </div>
          <div className="row">
            <div className="col s2">
              <button
                style={{
                  width: "150px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem",
                }}
                onClick={this.onDisplayClick}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Display
              </button>
            </div>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="row">
                {/* <DemoContainer components={["DatePicker"]}> */}
                <DatePicker
                  label="Start Date"
                  className="date-picker-container"
                  inputFormat="MM/dd/yyyy"
                  value={this.state.startDate}
                  onChange={(value) => {
                    this.setState({ startDate: value });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  // maxDate={this.state.endDate}
                  // defaultValue={this.state.startDate}
                  // onChange={this.onStartDateChange}
                />
                <DatePicker
                  label="End Date"
                  className="date-picker-container"
                  inputFormat="MM/dd/yyyy"
                  value={this.state.endDate}
                  onChange={(value) => {
                    this.setState({ endDate: value });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  // minDate={this.state.startDate}
                  // maxDate={dayjs()}
                  // defaultValue={this.state.endDate}
                  // onChange={this.onEndDateChange}
                />
                {/* </DemoContainer> */}
              </div>
            </LocalizationProvider>
          </div>
        </div>
        <div className="row">{renderTable(this.state.comments)}</div>
        {this.state.selectedUser && this.state.canEditSentiment && (
          <div className="row" style={{ display: "flex" }}>
            <div className="col s4">
              <button
                style={{
                  width: "300px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem",
                  marginRight: "5rem",
                }}
                onClick={this.onSendNotification}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Send Batch Notifications
              </button>
            </div>
          </div>
        )}
        {this.state.slackMessageError && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.slackMessageError ? true : false}
            autoHideDuration={5000}
            onClose={() => this.setState({ slackMessageError: false })}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {"Somthing went wrong! Messsage was not sent"}
            </Alert>
          </Snackbar>
        )}
        {this.state.editSentimentError && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.editSentimentError ? true : false}
            autoHideDuration={5000}
            onClose={() => this.setState({ editSentimentError: false })}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {"Somthing went wrong! Edit sentiment was not changed"}
            </Alert>
          </Snackbar>
        )}

        {this.state.editSentimentSuccess && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.editSentimentSuccess ? true : false}
            autoHideDuration={5000}
            onClose={() => this.setState({ editSentimentSuccess: false })}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              {"Edit sentiment was changed successfully"}
            </Alert>
          </Snackbar>
        )}
        {this.state.slackMessageSent && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.slackMessageSent ? true : false}
            autoHideDuration={5000}
            onClose={() => this.setState({ slackMessageSent: false })}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              {"Message was sent successfully"}
            </Alert>
          </Snackbar>
        )}

        <Modal
          open={this.state.rejectModalOpen}
          onClose={() => {
            this.setState({
              rejectModalOpen: false,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reject with the correct sentiment
            </Typography>

            <Box
              component="form"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
                marginBlock: "10px",
              }}
              noValidate
              autoComplete="off"
            >
              <Select
                label="reject sentiment"
                onChange={(e) => {
                  this.setState({ correctSentimentByReject: e.target.value });
                }}
                value={this.state.correctSentimentByReject}
                style={{
                  width: "50%",
                  display: "block",
                }}
              >
                <MenuItem value="positive">Positive</MenuItem>
                <MenuItem value="negative">Negative</MenuItem>
                <MenuItem value="neutral">Neutral</MenuItem>
              </Select>

              <Button
                variant="outlined"
                onClick={() => {
                  this.props.editSentiment({
                    username: this.state.currentRow.username,
                    commentId: this.state.currentRow.commentId,
                    status: "reject",
                    updatedBy: this.state.loggedInUser,
                    feedback: this.state.currentRow.feedback,
                    platform: this.state.currentRow.data.platform,
                    oldSentiment: this.state.correctSentimentByReject,
                    _id: this.state.currentRow._id,
                  });

                  this.setState({
                    rejectModalOpen: false,
                  });
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
}

SentimentTab.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  listUsers: PropTypes.func.isRequired,
  listSentimentDetails: PropTypes.func.isRequired,
  sendSentimentNotification: PropTypes.func.isRequired,
  editSentiment: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
  listUsers,
  listSentimentDetails,
  editSentiment,
  sendSentimentNotification,
})(SentimentTab);
