import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import {
  setCurrentUser,
  logoutUser,
  refreshToken,
} from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import MyNavbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
// import ProtectedRoute from "./components/private-route/ProtectedRoute";
// import SuperRoute from "./components/private-route/SuperRoute";
import UserTab from "./components/tabs/UserTab";
import BrandTab from "./components/tabs/BrandTab";
import SourceTab from "./components/tabs/SourceTab";
import RequestsTab from "./components/tabs/RequestsTab";
import SentimentTab from "./components/tabs/SentimentTab";
import ApprovalRedirect from "./components/tabs/ApprovalRedirect";

import "./App.css";
import SentimentSpamTab from "./components/tabs/SentimentSpamTab";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    if (currentTime - decoded.exp < 86400000) {
      //one day
      store.dispatch(refreshToken(decoded));
    } else {
      store.dispatch(logoutUser());
      window.location.href = "./login";
    }
    //store.dispatch(logoutUser());
    // Redirect to login
    //window.location.href = "./login";
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <MyNavbar />
            <Route exact path="/" component={Landing} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Switch>
              <PrivateRoute exact path="/user" component={UserTab} />
              <PrivateRoute exact path="/brand" component={BrandTab} />
              <PrivateRoute exact path="/source" component={SourceTab} />
              <PrivateRoute exact path="/requests" component={RequestsTab} />
              <PrivateRoute exact path="/sentiment" component={SentimentTab} />
              <PrivateRoute
                exact
                path="/sentiment-spam-tab"
                component={SentimentSpamTab}
              />
              <PrivateRoute
                exact
                path="/requests/:requestId/approve"
                component={ApprovalRedirect}
              />
              {/* <ProtectedRoute path="/monitor" component={Monitor} />
              <SuperRoute path="/settings" component={Users} /> */}
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
